import React from 'react'
import { FaChevronDown } from 'react-icons/fa'

import styles from './footer.module.scss'

class Footer extends React.Component {
    state={
        now: new Date().getFullYear(),
    }
    render() {
        return (
            <footer className={styles.footer}>
                <p>&copy; {this.state.now} Stefan Perales</p>
                <p>Built with <span className={styles.love}>❤</span> by <a href="https://github.com/peralysis">peralysis</a></p>
            </footer>
        )
    }
}

export default Footer;