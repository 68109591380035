import React from 'react'
import { Link } from 'gatsby'
import { FaGithubAlt, FaRss, FaTwitter } from 'react-icons/fa'

import styles from './navigation.module.scss'

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isToggleOn: false};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();

    if (!this.state.isToggleOn) {
      document.body.classList.add('navigation-is-open');
    }
    else {
      document.body.classList.remove('navigation-is-open');
    }

    this.setState(state => ({
      isToggleOn: !state.isToggleOn
    }));

    return true;
  }

  render() {
    return (
      <>
        <a href="#navbar" id="menu-burger" className={styles.menuBurger} onClick={this.handleClick} >Menu<span className={styles.navIcon}></span>

          <svg x="0px" y="0px" width="54px" height="54px" viewBox="0 0 54 54">
            <circle
              fill="transparent"
              stroke="#000000"
              strokeWidth="1"
              cx="27"
              cy="27"
              r="25"
              strokeDasharray="157 157"
              strokeDashoffset="157"
            ></circle>
          </svg>
        </a>

        <div id="navbar" className={styles.navbar}>
          <div className={styles.navigationWrapper}>
            <div className={styles.halfBlock}>
              <h2>Navigation</h2>

              <nav>
                <ul className={styles.primaryNav}>
                  <li><a href="/">Home</a></li>
                  <li><a href="/blog">Blog</a></li>
                  <li><a href="/#about" onClick={() => {this.handleClick}}>About</a></li>
                  <li>
                    <a href="https://github.com/Peralysis" target="_blank" rel="noreferrer">
                      <i className="icon"><FaGithubAlt className="icon"/></i> Github
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/StefanPerales" target="_blank" rel="noreferrer">
                      <i className="icon"><FaTwitter/></i> Twitter
                    </a>
                  </li>
                  <li>
                    <a href="/atom.xml" target="_blank" rel="noreferrer">
                      <i className="icon fa"><FaRss /></i> RSS
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Navigation